import {
  Body,
  Button,
  Card,
  CardLevel,
  Frow,
  Intent,
  PaddingLevel,
  Subheading1,
  Tooltip,
  WorkflowTag,
} from '@pinpointhq/thumbtack';
import { format } from 'date-fns';
import * as React from 'react';
import ReactTable from 'react-table';
import { visit } from 'turbolinks';
import { exportAdminProgrammeReleaseRequestsPath } from '../../../../javascript/application/ts_routes';
import { ReleaseRequest } from '../../../../javascript/models';
import { Checkbox } from '../../../shared/Checkbox';
import { HeaderCheckbox } from '../../../shared/HeaderCheckbox';
import useVisibility from '../../../shared/hooks/useVisibility';
import Search from '../../../shared/Search';
import { useSpraypaintTableNamedParams } from '../../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../../shared/NewPaginationComponent';
import { RejectDialog } from '../../shared/RejectDialog';
import { ApproveDialog } from './releaseTable/ApproveDialog';
import { ReleaseRequestActionBar } from './releaseTable/ReleaseRequestActionBar';

export function ReleaseTable({ cycleId, programmeId }) {
  const identifier = 'released-applications';
  const [selection, setSelection] = React.useState([]);

  const { isOpen: approveOpen, handleClose: handleApproveClose, handleOpen: handleApproveOpen } = useVisibility();
  const { isOpen: rejectOpen, handleClose: handleRejectClose, handleOpen: handleRejectOpen } = useVisibility();

  const { fetchData, tableProps, setQuery } = useSpraypaintTableNamedParams({
    initialScope: ReleaseRequest.includes([{ application: [{ job: ['company'] }, 'job_seeker'] }]).order({
      id: 'desc',
    }),
    searchIdentifier: 'released-applications',
  });

  const columns = [
    {
      Cell: ({ original }) => <Checkbox resource={original} data={tableProps.data} {...{ selection, setSelection }} />,
      Header: () => <HeaderCheckbox selection={selection} setSelection={setSelection} data={tableProps.data} />,
      accessor: 'id',
      className: '-checkbox',
      headerClassName: '-checkbox',
      // Max width is the checkbox size (16px) + 8px of padding either side. Needs to be 40 due to IE..
      maxWidth: 40,
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.application.jobSeeker.fullName}</div>;
      },
      Header: 'Name',
      accessor: 'fullName',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.application.job.companyName}</div>;
      },
      Header: 'Company name',
      accessor: 'companyName',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.application.job.workstream}</div>;
      },
      Header: 'Workstream',
      accessor: 'workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{format(row.original.createdAt, 'MMMM Do YYYY')}</div>;
      },
      Header: 'Requested on',
      accessor: 'createdAt',
    },
    {
      Cell: (row: any) => {
        return (
          <div>
            <Tooltip content={row.original.reason}>{row.original.reason}</Tooltip>
          </div>
        );
      },
      Header: 'Reason',
      accessor: 'releaseReason',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        if (row.original.status === 'accepted') {
          return <WorkflowTag intent={Intent.SUCCESS} text="Confirmed" />;
        }
        if (row.original.status === 'rejected') {
          return <WorkflowTag intent={Intent.DANGER} text="Rejected" />;
        }
        if (row.original.status === 'automatically_rejected') {
          return <WorkflowTag intent={Intent.DANGER} text="Automatically rejected" />;
        }
        return <WorkflowTag intent={Intent.NONE} text="Unconfirmed" />;
      },
      Header: 'Status',
      accessor: 'status',
      sortable: false,
    },
  ];

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const handleSearch = (searchTerm: string) => {
    setQuery({ search: searchTerm });
  };

  const handleExport = () => {
    visit(exportAdminProgrammeReleaseRequestsPath(programmeId, { cycle_id: cycleId }));
  };

  const handleCancel = () => {
    handleRejectClose();
    handleApproveClose();
    setSelection([]);
  };

  return (
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-2">
        <Subheading1>Requests to release</Subheading1>
        <Body muted={true} className="mar-t-1">
          Applications that have been requested to be released by companies
        </Body>
      </div>
      <div className="mar-b-2">
        <div style={{ display: selection.length > 0 ? 'block' : 'none' }}>
          <div>
            <ReleaseRequestActionBar
              handleApproveOpen={handleApproveOpen}
              handleRejectOpen={handleRejectOpen}
              onCancel={handleCancel}
            />
          </div>
        </div>
        <Frow gutters={16} verticalGutters={16} style={{ display: selection.length > 0 ? 'none' : 'flex' }}>
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} handleSearch={handleSearch} />
          </div>
          <div>
            <Button minimal={true} text="Export" large={true} onClick={handleExport} />
          </div>
        </Frow>
      </div>
      <ReactTable
        {...tableProps}
        columns={columns}
        PaginationComponent={NewPaginationComponent}
        getPaginationProps={paginationProps}
        className="-unthemed"
        minRows={1}
      />
      {approveOpen && (
        <ApproveDialog
          fetchData={fetchData}
          handleClose={handleCancel}
          selection={selection}
          setSelection={setSelection}
        />
      )}
      {rejectOpen && (
        <RejectDialog
          fetchData={fetchData}
          handleClose={handleCancel}
          selection={selection}
          setSelection={setSelection}
        />
      )}
    </Card>
  );
}
