import { Attr, BelongsTo, HasMany, HasOne, Model, SpraypaintBase } from 'spraypaint';

@Model()
export class ApplicationRecord extends SpraypaintBase {
  public static apiNamespace = '/admin/graphiti';
  public static baseUrl = '';
}

@Model()
export class Application extends ApplicationRecord {
  public static jsonapiType = 'applications';

  @Attr() public cycleId: string;
  @Attr() public hiredOn: string;
  @Attr() public programmeId: string;
  @Attr() public status: string;
  @Attr() public requestToRelease: boolean;
  @Attr() public releaseReason: string;
  @Attr() public confirmedRelease: boolean;
  @Attr() public rejectedRelease: boolean;
  @Attr({ persist: false }) public programmeCycleName: string;

  @BelongsTo() public job: Job;
  @BelongsTo() public jobSeeker: JobSeeker;
}

@Model()
export class Candidate extends ApplicationRecord {
  public static jsonapiType = 'candidates';

  @Attr() public eligible: boolean;
  @Attr() public email: string;
  @Attr() public firstName: string;
  @Attr() public fullName: string;
  @Attr() public lastName: string;
  @Attr() public redFlag: boolean;
  @Attr() public redFlagReason: string;
  @HasMany() public jobSeekers: JobSeeker[];
}

@Model()
export class User extends ApplicationRecord {
  public static jsonapiType = 'users';

  @Attr() public email: string;
  @Attr() public fullName: string;
  @Attr() public superAdmin: boolean;
}

@Model()
export class Cycle extends ApplicationRecord {
  public static jsonapiType = 'cycles';

  @Attr() public name: string;
  @Attr() public shortName: string;
}

@Model()
export class Company extends ApplicationRecord {
  public static jsonapiType = 'companies';

  @Attr() public clickupId: string;
  @Attr() public usingExternalAts: string;

  @Attr() public blacklisted: boolean;
  @Attr() public blacklistedAt: string;
  @Attr() public blacklistReason: string;
  @Attr() public blacklistedById: string;

  @Attr() public favourite: boolean;
  @Attr() public favouritedAt: string;
  @Attr() public favouriteReason: string;
  @Attr() public favouritedById: string;

  @Attr() public redFlagged: boolean;
  @Attr() public redFlaggedAt: string;
  @Attr() public redFlagReason: string;
  @Attr() public redFlaggedById: string;

  @Attr() public membershipStatus: string;

  @Attr() public uid: string;

  @Attr() public name: string;
  @Attr() public workstream: string;
  @Attr() public registeredCompanyNumber: string;
  @Attr() public uniqueDivisionIdentifier: string;
  @Attr() public companyAddress: string;
  @Attr() public considerApplicant: boolean;
  @Attr() public provideVisaSponsor: boolean;
  @Attr() public primaryName: string;
  @Attr() public primaryPhone: string;
  @Attr() public primaryEmail: string;
  @Attr() public secondaryName: string;
  @Attr() public secondaryPhone: string;
  @Attr() public secondaryEmail: string;
  @Attr() public internships: { number: string; region: string; location: string; workstream: string; title: string }[];
  @Attr() public applicationsReviewed: string;
  @Attr() public interviewsCompleted: string;
  @Attr() public offersMade: string;
  @Attr() public hiresMade: string;

  @Attr() public zeroHire: boolean;
  @Attr() public zeroHireExplanation: string;

  // Extra Attribute
  @Attr() public currentPledge: number;
  @Attr() public previousPledge: number;
  @Attr() public previousHires: number;
  @Attr() public allocationInformation: any;

  @HasMany() public contacts: Contact;
  @HasMany() public companiesCycles: CompaniesCycle[];
  @HasMany() public jobs: Job[];
  @HasMany() public companySubmissions: CompanySubmission[];
  @BelongsTo() public blacklistedBy: User;
  @BelongsTo() public favouritedBy: User;
  @BelongsTo() public redFlaggedBy: User;
}

@Model()
export class CompaniesCycle extends ApplicationRecord {
  public static jsonapiType = 'companies_cycles';

  @Attr() public cycleId: string;
  @Attr() public programmeId: string;
  @Attr() public withdrawn: boolean;
  @Attr() public allocationPhase: string;
  @Attr() public programmeProgress: string;

  // Extra attributes
  @Attr() public cycleName: string;
  @Attr() public programmeName: string;
  @Attr({ persist: false }) public readonly pledgeStatus: string;

  @BelongsTo() public company: Company;
  @HasOne() public companyCycleInformation: CompanyCycleInformation;
  @HasOne() public companySubmission: CompanySubmission;
  @HasOne() public companyTimeline: CompanyTimeline;
}

@Model()
export class CompanyCycleInformation extends ApplicationRecord {
  public static jsonapiType = 'company_cycle_informations';

  @Attr() public initialPledge: number;
  @Attr() public jobsCount: number;
  @Attr() public successfulCandidatesCount: number;
  @Attr() public offersExtended: number;
  @Attr() public numberOfHires: number;
  @Attr() public currentPledge: number;
  @Attr() public percentageOfPledge: string;
}

@Model()
export class CompanySubmission extends ApplicationRecord {
  public static jsonapiType = 'company_submissions';

  @Attr({ persist: false }) public additionalPrimaryContacts: { name: string; email: string; phone: string }[];
  @Attr({ persist: false }) public companiesCycleId: string;
  @Attr({ persist: false }) public companyName: string;
  @Attr({ persist: false }) public completedAt: string;
  @Attr({ persist: false }) public contactName: string;
  @Attr({ persist: false }) public contactMobileNumber: string;
  @Attr({ persist: false }) public contactEmail: string;
  @Attr({ persist: false }) public createdAt: string;
  @Attr({ persist: false }) public internships: {
    number: string;
    region: string;
    location: string;
    workstream: string;
    title: string;
  }[];
  @Attr({ persist: false }) public secondaryContactName: string;
  @Attr({ persist: false }) public secondaryContactMobileNumber: string;
  @Attr({ persist: false }) public secondaryContactEmail: string;
  @Attr({ persist: false }) public sponsorship: boolean;
  @Attr({ persist: false }) public nonEligibleSponsorship: boolean;
  @Attr({ persist: false }) public token: string;
  @Attr({ persist: false }) public updatedAt: string;
  @Attr({ persist: false }) public workstream: string;
  @Attr({ persist: false }) public name: string;

  @HasMany() public savedInternships: Internship[];
}

@Model()
export class CompanyTimeline extends ApplicationRecord {
  public static jsonapiType = 'company_timelines';

  @Attr() public companiesCycleId: string;
  @Attr() public completeHires: string;
  @Attr() public finaliseOffers: string;
  @Attr() public firstContactDate: string;
  @Attr() public internshipEndDate: string;
  @Attr() public internshipStartDate: string;
  @Attr() public makeOffers: string;
  @Attr() public startInterviews: string;

  // New dates
  @Attr() public reviewStartDate: string;
  @Attr() public reviewEndDate: string;
  @Attr() public assessmentStartDate: string;
  @Attr() public assessmentEndDate: string;
  @Attr() public offeringStartDate: string;
  @Attr() public offeringEndDate: string;
  @Attr() public hiredStartDate: string;
  @Attr() public hiredEndDate: string;
}

@Model()
export class Contact extends ApplicationRecord {
  public static jsonapiType = 'contacts';

  @Attr() public companyId: string;
  @Attr() public contactType: string;
  @Attr() public createdAt: string;
  @Attr() public email: string;
  @Attr() public name: string;
  @Attr() public phone: string;
  @Attr({ persist: false }) public readonly programmeNames: string[];
  @Attr() public programmeIds: string[];
  @Attr() public status: string;
  @Attr() public subscribed: boolean;
  @Attr({ persist: false }) public readonly primary: boolean;
  @Attr({ persist: false }) public readonly secondary: boolean;

  @Attr() public markAsPrimary: boolean;
  @Attr() public markAsSecondary: boolean;
}

@Model()
export class Internship extends ApplicationRecord {
  public static jsonapiType = 'internships';

  @Attr() public length: string;
  @Attr() public number: number;
  @Attr() public pay: string;
  @Attr() public region: string;
  @Attr() public workstream: string;
  @Attr() public title: string;
  @Attr() public location: string;
  @Attr() public remote: boolean;
}

@Model()
export class Job extends ApplicationRecord {
  public static jsonapiType = 'jobs';

  @Attr() public additionalAllocationCount: number;
  @Attr() public applicationsByHeadcount: number;
  @Attr() public applicationsCount: number;
  @Attr() public closeReason: string;
  @Attr() public companyName: string;
  @Attr() public companyId: string;
  @Attr({ persist: false }) public cycleId: string;
  @Attr() public headcount: number;
  @Attr({ persist: false }) public internshipId: string;
  @Attr() public nonRejectedApplicationsCount: number;
  @Attr() public phase: string;
  @Attr({ persist: false }) public pinpointJobId: string;
  @Attr({ persist: false }) public programmeId: string;
  @Attr() public progress: string;
  @Attr() public sponsorship: boolean;
  @Attr() public title: string;
  @Attr() public region: string;
  @Attr() public workstream: string;
  @Attr() public removeExistingCandidates: boolean;

  @Attr() public closeJob: boolean;
  @Attr() public withdrawJob: boolean;
  @Attr() public createInPinpoint: boolean;
  @BelongsTo() public cycle: Cycle;
  @BelongsTo() public programme: Programme;
  @BelongsTo() public company: Company;
}

@Model()
export class JobSeeker extends ApplicationRecord {
  public static jsonapiType = 'job_seekers';

  @Attr() public createdAt: string;
  @Attr() public cycleId: string;
  @Attr() public degreeSubject: string;
  @Attr() public eligible: boolean;
  @Attr() public programmeId: string;
  @Attr() public email: string;
  @Attr() public firstName: string;
  @Attr() public fullName: string;
  @Attr() public lastName: string;
  @Attr() public university: string;
  @Attr() public secondPhase: boolean;
  @Attr() public discoverySource: string;
  @Attr() public programmeCycleName: string;
  @Attr() public cvUrl: string;
  @Attr() public otherUniversityName: string;
  @Attr() public yearOfGraduation: string;
  @Attr() public yearOfStudyMoreInfo: number;
  @Attr() public levelOfEducation: string;
  @Attr() public discoverySourceMoreInfo: string;
  @Attr() public unconditionalOffer: boolean;
  @Attr() public exactDegreeTitle: string;
  @Attr() public ethnicity: string;
  @Attr() public otherEthnicBackground: string;
  @Attr() public visaRequired: string;
  @Attr() public visaRequiredReason: string;
  @Attr() public visaNotRequiredReason: string;
  @Attr() public moreVisaInfo: string;
  @Attr() public gender: string;
  @Attr() public refugeeOrAsylumStatus: boolean;
  @Attr() public spentTimeInCareOrCarer: boolean;
  @Attr() public secondarySchoolType: string;
  @Attr() public highestQualificationOfParent: string;
  @Attr() public eligibleForSchoolMeals: string;
  @Attr() public householdIncome: string;
  @Attr() public postcodeAt14: string;
  @Attr() public phoneNumber: string;
  @Attr() public parentOccupation: string;
  @Attr() public financialSupport: boolean;
  @Attr() public disabilityStatements: string;
  @Attr() public disabilityConditions: string;
  @Attr() public socialBarriers: string;
  @Attr() public furtherConditionInformation: string;
  @Attr() public reasonableAdjustments: string;
  @Attr() public reasonableAdjustmentsInformation: string;
  @Attr() public proficientSkills: string;

  @Attr() public internshipSectorPreferenceOne: string;
  @Attr() public internshipSectorPreferenceTwo: string;
  @Attr() public internshipSectorPreferenceThree: string;
  @Attr() public internshipSectorPreferenceOneRationale: string;
  @Attr() public internshipSectorPreferenceTwoRationale: string;
  @Attr() public internshipSectorPreferenceThreeRationale: string;

  @Attr() public eligibilityOverwrite: boolean;
  @Attr() public eligibilityOverwriteReason: string;

  // Extra attributes
  @Attr({ persist: false }) public readonly furthestStageReached: string;
  @Attr({ persist: false }) public readonly applicationCount: number;

  @HasMany() public addresses: Address[];
  @HasMany() public applications: Application[];
}

@Model()
export class Address extends ApplicationRecord {
  public static jsonapiType = 'addresses';

  @Attr() public address: string;
  @Attr() public postcode: string;
  @Attr() public latitude: string;
  @Attr() public longitude: string;
}

@Model()
export class ReleaseRequest extends ApplicationRecord {
  public static jsonapiType = 'release_requests';

  @Attr() public applicationId: string;
  @Attr() public createdAt: string;
  @Attr() public status: string;
  @Attr() public reason: string;
  @Attr() public response: string;
  @BelongsTo() public application: Application;
}

@Model()
export class Report extends ApplicationRecord {
  public static jsonapiType = 'reports';

  @Attr() public createdAt: string;
  @Attr() public name: string;
  @Attr() public columns: { label: string; value: string }[];
  @Attr() public filters: { property: string; operator: string; value: string }[];
  @Attr() public reportType: string;
  @Attr() public status: string;
}

@Model()
export class ReportTemplate extends ApplicationRecord {
  public static jsonapiType = 'report_templates';

  @Attr() public name: string;
  @Attr() public columns: { label: string; value: string }[];
  @Attr() public filters: { property: string; operator: string; value: string }[];
  @Attr() public reportType: string;
  @Attr() public updatedAt: string;
  @Attr() public singleUse: boolean;

  // Used to trigger a report run
  @Attr() public runReport: boolean;
}

@Model()
export class University extends ApplicationRecord {
  public static jsonapiType = 'universities';

  @Attr() public name: string;
  @Attr() public tier: string;
  // Extra attributes
  @Attr() public numberOfJobSeekers: number;
}

@Model()
export class Workstream extends ApplicationRecord {
  public static jsonapiType = 'workstreams';

  @Attr() public name: string;
}

@Model()
export class Programme extends ApplicationRecord {
  public static jsonapiType = 'programmes';

  @Attr() public name: string;
}

@Model()
export class AllocationRun extends ApplicationRecord {
  public static jsonapiType = 'allocation_runs';

  @Attr() public createdAt: string;
  @Attr() public userId: string;
  @Attr() public status: string;
  @BelongsTo() public user: User;
}

@Model()
export class AllocationRequest extends ApplicationRecord {
  public static jsonapiType = 'allocation_requests';

  @Attr() public reason: string;
  @Attr() public rejectionReason: string;
  @Attr() public createdAt: string;
  @Attr() public cycleId: string;
  @Attr() public jobId: string;
  @Attr() public userId: string;
  @Attr() public status: string;
  @Attr() public allocationOptions: any;

  @BelongsTo() public user: User;
  @BelongsTo() public job: Job;
}
