import { Card, CardLevel, Col, Drawer, FormGroup, Frow, PaddingLevel, Select } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { AllocationRun, Company } from '../../../javascript/models';
import { DrawerHeader } from '../../shared/DrawerHeader';
import { useSpraypaintNamedParams } from '../shared/hooks/useSpraypaint';

interface ICreateDrawer {
  allocationRun: AllocationRun;
  handleClose: () => void;
}

export function CreateDrawer({ allocationRun, handleClose }: ICreateDrawer) {
  const { data: companies, isLoading: isCompaniesLoading } = useSpraypaintNamedParams({
    initialScope: Company.where({ cycle_id: 4 }).per(10000),
  });
  const [attributes, setAttributes] = React.useState({});

  const companyOptions = companies.map((company) => ({ label: company.name, value: company.id }));

  const handleCompanySelect = (selectedItem) => {
    const updatedAttributes = { ...attributes, company_id: selectedItem ? selectedItem.value : null };
    setAttributes(updatedAttributes);
  };

  console.log(attributes);

  return (
    <Drawer maxWidth={600} isOpen={true} onClose={handleClose}>
      <DrawerHeader title="New Allocation Run" handleClose={handleClose} />
      <Card paddingLevel={PaddingLevel.EXPANDED} level={CardLevel.NONE}>
        <Frow flexDirection="column" verticalGutters={16}>
          <Col>
            <FormGroup label="Company">
              <Select
                items={companyOptions}
                onItemSelect={handleCompanySelect}
                loading={isCompaniesLoading}
                fill={true}
                buttonProps={{ large: true }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup label="Company"></FormGroup>
          </Col>
        </Frow>
      </Card>
    </Drawer>
  );
}
