import { Button, Card, CardLevel, Classes, Frow, Heading2, ISelectOption, PaddingLevel } from '@pinpointhq/thumbtack';
import { format } from 'date-fns';
import * as React from 'react';
import ReactTable from 'react-table';
import { AllocationRun } from '../../../javascript/models';
import useVisibility from '../../shared/hooks/useVisibility';
import humanize from '../../shared/utilities/humanize';
import { useSpraypaintTableNamedParams } from '../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../shared/NewPaginationComponent';
import { CreateDrawer } from './CreateDrawer';
import { ShowDrawer } from './ShowDrawer';

export default function Index({
  educationLevels,
  superAdmin,
}: {
  educationLevels: ISelectOption[];
  superAdmin: boolean;
}) {
  const { tableProps } = useSpraypaintTableNamedParams({
    initialScope: AllocationRun.includes(['user']).order({ created_at: 'desc' }),
    searchIdentifier: 'allocationRuns',
  });
  const { isOpen, handleOpen, handleClose } = useVisibility();
  const [selectedAllocationRun, setSelectedAllocationRun] = React.useState<AllocationRun | null>(null);

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const columns = [
    {
      Cell: ({ original }: { original: AllocationRun }) => {
        const handleClick = () => {
          setSelectedAllocationRun(original);
        };
        return (
          <div>
            <span className={Classes.LINK} onClick={handleClick}>
              {humanize(original.status)}
            </span>
          </div>
        );
      },
      Header: 'Status',
      accessor: 'status',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: AllocationRun }) => {
        return <div>{original.user ? original.user.fullName : 'Deleted User'}</div>;
      },
      Header: 'Created by',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: AllocationRun }) => {
        return <div>{format(original.createdAt, 'MMMM Do YYYY')}</div>;
      },
      Header: 'Created at',
      accessor: 'created_at',
      sortable: true,
    },
  ];

  return (
    <>
      <Card paddingLevel={PaddingLevel.NONE} level={CardLevel.RAISED}>
        <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED}>
          <Frow justifyContent="space-between" alignItems="center">
            <div>
              <Heading2 className="mar-b-0">Allocation Runs</Heading2>
            </div>
            <div>
              <Button text="New allocation run" onClick={handleOpen} />
            </div>
          </Frow>
        </Card>
        <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE}>
          <ReactTable
            {...tableProps}
            columns={columns}
            PaginationComponent={NewPaginationComponent}
            getPaginationProps={paginationProps}
            className="-unthemed"
            minRows={1}
          />
        </Card>
      </Card>
      {selectedAllocationRun && (
        <ShowDrawer handleClose={() => setSelectedAllocationRun(null)} allocationRun={selectedAllocationRun} />
      )}
      {isOpen && <CreateDrawer handleClose={handleClose} allocationRun={new AllocationRun({ status: 'scheduled' })} />}
    </>
  );
}
