import { Card, CardLevel, Drawer, PaddingLevel } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { DrawerHeader } from '../../shared/DrawerHeader';

export function ShowDrawer({ allocationRun, handleClose }) {
  return (
    <Drawer maxWidth={800} isOpen={true} onClose={handleClose}>
      <DrawerHeader title="Allocation Run" handleClose={handleClose} />
      <Card paddingLevel={PaddingLevel.EXPANDED} level={CardLevel.NONE}>
        Stuff here
      </Card>
    </Drawer>
  );
}
